<div class="textos">
    <h2 class="textos">Añadir un plato a la carta
        <button mat-icon-button>
            <mat-icon style="color: green;" (click)="openAddMod(newRecord)">add</mat-icon>
        </button>
    </h2>
    <h2 class="textos">Entrantes</h2>
    <div *ngFor="let element of dataSourceEntrantes | sort:'indice'">
        <p class="textos">{{element.indice}} -> {{element.nombre}} - {{element.precio}}
            <a *ngFor="let alergy of splitDescription(element.alergias)">
                <img class="icono" src="../../../assets/img/{{alergy}}.png"  alt="">
            </a>
            <button mat-icon-button (click)="openAddMod(element)">
                <mat-icon style="color: green;">edit</mat-icon>
            </button>
            <button mat-icon-button (click)="deleteElement(element.key)">
                <mat-icon style="color: green;">clear</mat-icon>
            </button>
        </p>
    </div>

    <h2 class="textos">Principales</h2>
    <div *ngFor="let element of dataSourcePrincipales | sort:'indice'">
        <p class="textos">{{element.indice}} -> {{element.nombre}} - {{element.precio}}
            <a *ngFor="let alergy of splitDescription(element.alergias)">
                <img class="icono" src="../../../assets/img/{{alergy}}.png"  alt="">
            </a>
            <button mat-icon-button (click)="openAddMod(element)">
                <mat-icon style="color: green;">edit</mat-icon>
            </button>
            <button mat-icon-button (click)="deleteElement(element.key)">
                <mat-icon style="color: green;">clear</mat-icon>
            </button>
        </p>
    </div>

    <h2 class="textos">Postres</h2>
    <div *ngFor="let element of dataSourcePostres | sort:'indice'">
        <p class="textos">{{element.indice}} -> {{element.nombre}} - {{element.precio}}
            <a *ngFor="let alergy of splitDescription(element.alergias)">
                <img class="icono" src="../../../assets/img/{{alergy}}.png"  alt="">
            </a>
            <button mat-icon-button (click)="openAddMod(element)">
                <mat-icon style="color: green;">edit</mat-icon>
            </button>
            <button mat-icon-button (click)="deleteElement(element.key)">
                <mat-icon style="color: green;">clear</mat-icon>
            </button>
        </p>
    </div>
</div>
