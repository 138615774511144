<div class="textos">
    <h1 class="textos"> El Romero </h1>
    <p class="textos2">
        El Romero nace en 2014 como un restaurante familiar con el objetivo de 
        que comer fuera te haga sentir como en casa. Se trata de una cocina sincera, con sabor y con mucho mimo.
    </p>
    <img src="../../../assets/img/image_4.jpeg" alt="Comedor">
    <p class="textos2">
        Basada en la cocina tradicional gallega, pero con toques modernos, la cocina del Romero se apoya en el producto. ¡Nos encanta ir a la Plaza y traer los productos
        más frescos para vosotros! Lo importante aquí es el respeto hacia la materia prima.
    </p>
    <img src="../../../assets/img/image_9.jpeg" alt="Maremoto">
    <p class="textos2"></p>
    <p class="textos2">
        Todo está cuidado y pensado para que comer aquí sea como sentirse en casa. Esperamos que vengáis a nuestra casa y disfrutéis tanto como lo hacemos nosotros.
    </p>
    <p class="textos2">
        El Romero
    </p>
    <img style="width: 30%; height: 30%;"src="../../../assets/img/logo.png" alt="Logo">
</div>