<div class="custom-form">
    <div class="flex-container text-center" appResizeDialog>
        <h1>Modificar Carta</h1>
    </div>
    
    <form novalidate #form="ngForm" (ngSubmit)="onSubmit(form.value)">

        <div class="text-center">
            <mat-label>Nombre del plato*</mat-label>
            <input #nombre class="form-control text-center" ngModel matInput required name="name" [(ngModel)]="record.nombre"/>
        </div>

        <div class="text-center">
            <mat-label>Precio*</mat-label>
            <input #price class="form-control text-center" ngModel matInput required name="price" [(ngModel)]="record.precio"/>
        </div>

        <div class="text-center">
            <mat-label>Índice*</mat-label>
            <input #index class="form-control text-center" ngModel matInput required name="index" [(ngModel)]="record.indice"/>
        </div>

        <div class="text-center">
                <mat-label>Clase*</mat-label>
                <mat-select #class class="form-control text-center" ngModel matInput required name="class" [(ngModel)]="record.clase">
                    <mat-option class="form-control text-center" *ngFor="let clase of clases" [value]="clase">
                        {{clase}}
                    </mat-option>
                </mat-select>
        </div>
    
        <div class="text-center">
                <mat-label>Alergías*</mat-label>
                <!-- <input #alergy class="form-control text-center" ngModel matInput required name="alergy" [(ngModel)]="record.alergias"/> -->
                <div class="row">
                    <div class="col">
                        <mat-checkbox #leche style="float: left;" (click)="addAlergia(leche.value, 'leche')" [value]="true">Lácteos</mat-checkbox>
                    </div>
                    <div class="col">
                        <mat-checkbox #gluten style="padding-right: 0;" (click)="addAlergia(gluten.value, 'gluten')" [value]="true">Gluten</mat-checkbox>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <mat-checkbox #pescado style="float: left;" (click)="addAlergia(pescado.value, 'pescado')" [value]="true">Pescados</mat-checkbox> 
                    </div>
                    <div class="col">
                        <mat-checkbox #concha style="padding-right: 0;" (click)="addAlergia(concha.value, 'concha')" [value]="true">Bivalvos</mat-checkbox>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <mat-checkbox #crustaceo style="float: left;" (click)="addAlergia(crustaceo.value, 'crustaceo')" [value]="true">Crustáceo</mat-checkbox>
                    </div>
                    <div class="col">
                        <mat-checkbox #sulfito style="padding-right: 0;" (click)="addAlergia(sulfito.value, 'sulfito')" [value]="true">Sulfitos</mat-checkbox>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <mat-checkbox #huevo style="float: left;" (click)="addAlergia(huevo.value, 'huevo')" [value]="true">Huevo</mat-checkbox>
                    </div>
                    <div class="col">
                        <mat-checkbox #frutoseco style="padding-right: 0;" (click)="addAlergia(frutoseco.value, 'frutoseco')" [value]="true">Frutos Secos</mat-checkbox>
                    </div>
                </div>
                
        </div>

        <div class="text-center">
            <button mat-raised-button color="accent"
                    type="submit">Enviar</button>
        </div>
        
    </form>
</div>