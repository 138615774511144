<mat-dialog-content
  (swipeleft)="changeImg(1)"
  (swiperight)="changeImg(-1)"
>
  <div fxLayout="row" fxLayoutAlign="center center">
    <!-- <button fxHide fxShow.gt-sm *ngIf="!image.first" mat-button (click)="changeImg(-1)">
      <mat-icon>chevron_left</mat-icon>
    </button> -->
    <img class="mat-dialog-content-img"
         [src]="image.src"
         [alt]="image.alt"
         (click)="changeImg(1)"
        (swiperight)="changeImg(1)"
        (swipeleft)="changeImg(-1)">
    <!-- <button fxHide fxShow.gt-sm *ngIf="!image.last" mat-button (click)="changeImg(1)">
      <mat-icon>chevron_right</mat-icon>
    </button> -->
  </div>
</mat-dialog-content>