import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';
import { AdminManagementComponent } from '../components/admin-management/admin-management.component';


@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {

  constructor(private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot): boolean {
    // ENTER AUTHORIZATION
    if (environment.isLogged !== undefined && environment.isLogged !== null && environment.isLogged === true) {
      // avoid enter on LoginComponent once user is logged
      if (route.component === AdminManagementComponent) {
        this.router.navigate(['']);
        return false;
      }
      return true;
    }

    // DEFAULT
    this.router.navigate(['admin']);
    return false;
  }
}
