<div class="custom-form">
    <div class="flex-container text-center" appResizeDialog>
        <h1>Modificar Cóctel</h1>
    </div>
    
    <form novalidate #form="ngForm" (ngSubmit)="onSubmit(form.value)">

        <div class="text-center">
            <mat-label>Nombre del cóctel*</mat-label>
            <input #nombre class="form-control text-center" ngModel matInput required name="name" [(ngModel)]="record.nombre"/>
        </div>

        <div class="text-center">
            <mat-label>Precio*</mat-label>
            <input #price class="form-control text-center" ngModel matInput required name="price" [(ngModel)]="record.precio"/>
        </div>

        <div class="text-center">
            <mat-label>Ingredientes*</mat-label>
            <input #index class="form-control text-center" ngModel matInput required name="composition" [(ngModel)]="record.ingredientes"/>
        </div>

        <div class="text-center">
            <button mat-raised-button color="accent"
                    type="submit">Enviar</button>
        </div>
        
    </form>
</div>