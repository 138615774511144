<div class="custom-form">
    <div class="flex-container text-center" appResizeDialog>
        <h1>Petición de Reserva</h1>
    </div>
    
    <form novalidate #form="ngForm" (ngSubmit)="onSubmit(form.value)">

        <div class="text-center">
            <mat-label>Nombre de contacto*</mat-label>
            <input #nombre class="form-control text-center" ngModel matInput required name="name"/>
        </div>

        <div class="text-center">
            <mat-label>Correo de contacto*</mat-label>
            <input #mail class="form-control text-center" ngModel matInput required name="email"/>
        </div>

        <div class="text-center">
                <mat-label>Teléfono*</mat-label>
                <input #phone style="text-decoration: underline;" ngModel class="form-control text-center" matInput required name="phone"/>
        </div>
    
        <div class="text-center">
                <mat-label>Alergías*</mat-label>
                <input #alergy class="form-control text-center" ngModel matInput required name="alergy"/>
        </div>

        <div class="text-center">
            <mat-label>Número de Personas*</mat-label>
            <input #pax class="form-control text-center" ngModel matInput required name="pax"/>
        </div>

        <div class="text-center">
            <mat-label>Fecha*</mat-label>
            <input placeholder="DD/MM/AAAA" #date class="form-control text-center" ngModel matInput required name="date">
        </div>

        <div class="text-center">
            <mat-label>Hora de la reserva*</mat-label>
            <mat-select #time class="form-control text-center" (click)="obtainHours(date.value)" ngModel matInput required name="time">
                <mat-option class="form-control text-center" *ngFor="let hora of horas" [value]="hora">
                    {{hora}}
                </mat-option>
            </mat-select>
        </div>

        <div class="text-center">
            <button mat-raised-button color="accent"
                    [disabled]="!phone.value || !alergy.value || !mail.value || !nombre.value || !time.value || !pax.value || !date.value" type="submit">Enviar</button>
        </div>
        
    </form>
</div>