import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DrinkModel } from 'src/app/model/drink.model';
import { FirebaseService } from 'src/app/services/firebase.service';
import { PopUpComponent } from '../pop-up/pop-up.component';
import { DrinkElementComponent } from '../drink-element/drink-element.component';

@Component({
  selector: 'app-update-drink',
  templateUrl: './update-drink.component.html',
  styleUrls: ['./update-drink.component.css']
})
export class UpdateDrinkComponent implements OnInit {
  dataSource: DrinkModel[];
  newRecord: DrinkModel;

  constructor(public dialog: MatDialog, private dbService: FirebaseService) {
}

  ngOnInit(): void {
    this.dbService.getDrinkList().snapshotChanges()
      .subscribe( item => {
        this.dataSource = [];
        item.forEach( element => {
          let x = element.payload.toJSON();
          x['key'] = element.key;
          this.dataSource.push(x as DrinkModel);
        })
    });
  }

  openDialog(from_: string) {
    let dialogRef;

    dialogRef = this.dialog.open(PopUpComponent, {
      disableClose: true,
      data: {from: from_}});

    dialogRef.afterClosed().subscribe(result => {
    });
  }

  deleteElement(key: string) {
    this.dbService.deleteDrink(key);
    this.ngOnInit();
    this.openDialog('drinkDelete');
  }

  openAddMod(element: DrinkModel) {
    let dialogRef;

    dialogRef = this.dialog.open(DrinkElementComponent, {
      data: {record: element}});

    dialogRef.afterClosed().subscribe(result => {
    });
  }

}
