import { Injectable } from '@angular/core';
import { AngularFireDatabase, AngularFireList } from '@angular/fire/database';

@Injectable({
  providedIn: 'root'
})
export class FirebaseService {
  bookingList: AngularFireList<any>;
  menuList: AngularFireList<any>;
  drinkList: AngularFireList<any>;

  constructor(private firebase: AngularFireDatabase) {
    this.bookingList = this.firebase.list('booking');
    this.menuList = this.firebase.list('menu');
    this.drinkList = this.firebase.list('drink');
   }

  getBookingList() {
    this.bookingList = this.firebase.list('booking');
    return this.bookingList;
  }

  insertBooking(booking) {
    this.bookingList.push({
        nombre: booking.name,
        correo: booking.email,
        telef: booking.phone,
        alergias: booking.alergy,
        pax: booking.pax,
        fecha:  booking.date,
        hora: booking.time});
  }

  getMenuList() {
    this.menuList = this.firebase.list('menu');
    return this.menuList;
  }

  insertMenu(menu) {
    this.menuList.push({
        nombre: menu.name,
        precio: menu.price,
        clase: menu.class,
        alergias: menu.alergy,
        indice: menu.index});
  }

  updateMenu(menu) {
    this.menuList.update(menu.key, {
      nombre: menu.name,
      precio: menu.price,
      clase: menu.class,
      alergias: menu.alergy,
      indice: menu.index
    });
  }

  deleteMenu($key: string) {
    this.menuList.remove($key);
  }

  getDrinkList() {
    this.drinkList = this.firebase.list('drink');
    return this.drinkList;
  }

  insertDrink(drink) {
    this.drinkList.push({
        nombre: drink.name,
        ingredientes: drink.composition,
        precio: drink.price});
  }

  updateDrink(drink) {
    this.drinkList.update(drink.key, {
      nombre: drink.name,
      ingredientes: drink.composition,
      precio: drink.price
    });
  }

  deleteDrink($key: string) {
    this.drinkList.remove($key);
  }

}
