<!-- <div class="textos bgsize2 boton" id="contain">
        <h1 class="textos"><strong>Horarios</strong></h1>
        <h2 class="textos"> <strong> Martes a Viernes de 09.30 a 16.30 y de 20.00 a 23.30
            <p> Sábados de 10.00 a 16.30 y de 20.00 a 23.30 </p> 
            <p> Domingos de 10.00 a 16.30 </p> 
            <p> Lunes cerrado</p> </strong>
        </h2>
        <strong><p> Santiago de Compostela</p>
        <p> Rúa das Galeras, 22, Baixo B</p>
        <p> 15701 </p>
        <p> Teléfono: 881817572 </p>
        <p type="email"> email: elromerodegaleras@gmail.com </p></strong>
        <button mat-raised-button color="accent" routerLink='booking'>Reserva una mesa</button>
</div>
<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2923.6026162308!2d-8.551349348248722!3d42.881230979053214!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd2efe426ed91229%3A0x1be5fb917410f2a7!2sEl%20Romero!5e0!3m2!1ses!2ses!4v1595489007060!5m2!1ses!2ses" 
        width="100%" 
        height="28%" 
        style="border:0;" 
        allowfullscreen="" 
        aria-hidden="false" 
        tabindex="0">
</iframe> 
<div class="textos">
        <img style="width: 40%; height: 40%;" src="../../../assets/img/logo.png" alt="">
</div>-->
<div class="textos">
        <img style="width: 40%; height: 40%;" src="../../../assets/img/logo.png" alt="">
        <h1 class="textos"><strong>Horarios</strong></h1>
        <h2 class="textos"> <strong> 
                Martes de 13.00 a 16.30
            <p> Miércoles a Viernes de 13.00 a 16.30 y de 20.00 a 23.30 </p>
            <p> Sábados de 10.00 a 16.30 y de 20.00 a 23.30 </p> 
            <p> Domingos y festivos de 10.00 a 17.00 </p> 
            <p> Lunes cerrado</p> </strong>
        </h2>
        <strong><p> Santiago de Compostela</p>
        <p> Rúa das Galeras, 22, Baixo B</p>
        <p> 15701 </p>
        <p> Teléfono: 881817572 </p>
        <p type="email"> email: elromerodegaleras@gmail.com </p></strong>
</div>
<div class="boton">
        <button mat-raised-button color="accent" routerLink='booking'>Reserva una mesa</button>
</div>