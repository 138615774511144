import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { DrinkModel } from 'src/app/model/drink.model';
import { FirebaseService } from 'src/app/services/firebase.service';
import { PopUpComponent } from '../pop-up/pop-up.component';

@Component({
  selector: 'app-drink-element',
  templateUrl: './drink-element.component.html',
  styleUrls: ['./drink-element.component.css']
})
export class DrinkElementComponent implements OnInit {
  record: DrinkModel;
  clases = ['entrante', 'principal'];

  constructor(@Inject(MAT_DIALOG_DATA) data, private dbService: FirebaseService, public dialog: MatDialog) {
    this.record = data.record;

    if (this.record === undefined) {
      this.record = new DrinkModel();
      this.record.nombre = '';
      this.record.precio = '';
      this.record.ingredientes = '';
    } 
   }

  ngOnInit(): void {
  }


  onSubmit(form) {
    form.key = this.record.key;
    if (this.record.key === undefined || this.record.key === null) {
      this.dbService.insertDrink(form);
      this.openDialog('drinkAdd');
    } else {
      this.dbService.updateDrink(form);
      this.openDialog('drinkMod');
    }

  }

  openDialog(from_: string) {
    let dialogRef;

    dialogRef = this.dialog.open(PopUpComponent, {
      disableClose: true,
      data: {from: from_}});

    dialogRef.afterClosed().subscribe(result => {
      this.dialog.closeAll();
    });
  }

}
