<div class="textos">
    <h1 class="textos">Cócteles</h1>

    <div *ngFor="let element of dataSource | sort:'indice'">
        <p class="textos">{{element.nombre}}: {{element.ingredientes}} - {{element.precio}}
        </p>
    </div>

    <h2 class="textos">Consulte nuestros cócteles sin alcohol.</h2>
</div>
