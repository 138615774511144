import { Observable, BehaviorSubject } from 'rxjs';
import { GalleryImage } from '../model/gallery-image';
import { Constants } from '../utils/constants';

export class GalleryService {

    private galleryImages: GalleryImage[] = [];
    private gallery$: BehaviorSubject<GalleryImage[]> = new BehaviorSubject(this.galleryImages);
    private gallerySelected$: BehaviorSubject<GalleryImage> = new BehaviorSubject(undefined);
    private constants = new Constants();

    constructor() {
    }

    getGallery(): Observable<GalleryImage[]> {
      return this.gallery$.asObservable();
    }

    getImageSelected(): Observable<GalleryImage> {
      return this.gallerySelected$.asObservable();
    }

    createGallery(): void {
      this.galleryImages = [];
      for (let i = 0; i < 10; i++) {
        this.galleryImages.push(
          {
            src: `../../../assets/img/image_${i}.jpeg`,
            position: i,
            alt: `Image ${i}`,
            first: (i === 0),
            last: (i === this.constants.maxImages)
          });
      }
      console.log(this.galleryImages);
      this.gallery$.next(this.galleryImages);
    }

    selectImage(position: number): void {
      if (position >= 0 && position < this.galleryImages.length) {
        this.gallerySelected$.next(this.galleryImages[position]);
      }
    }
  }
