import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomePageComponent } from './components/home-page/home-page.component';
import { ContactUsComponent } from './components/contact-us/contact-us.component';
import { AboutUsComponent } from './components/about-us/about-us.component';
import { BookingComponent } from './components/booking/booking.component';
import { GalleryComponent } from './components/gallery/gallery.component';
import { MenuComponent } from './components/menu/menu.component';
import { PrivacyTermsComponent } from './components/privacy-terms/privacy-terms.component';
import { BrunchComponent } from './components/brunch/brunch.component';
import { MenuXmasComponent } from './components/menu-xmas/menu-xmas.component';
import { AdminManagementComponent } from './components/admin-management/admin-management.component';
import { UpdateMenuComponent } from './components/update-menu/update-menu.component';
import { AuthGuard } from './services/auth-guard.service';
import { NewYearMenuComponent } from './components/new-year-menu/new-year-menu.component';
import { DrinkComponent } from './components/drink/drink.component';
import { UpdateDrinkComponent } from './components/update-drink/update-drink.component';


const routes: Routes = [
  {path: '', component: HomePageComponent},
  {path: 'aboutUs', component: AboutUsComponent},
  {path: 'booking', component: BookingComponent},
  {path: 'gallery', component: GalleryComponent},
  {path: 'menu', component: MenuComponent},
  {path: 'drink', component: DrinkComponent},
  {path: 'contactUs', component: ContactUsComponent},
  {path: 'privacy', component: PrivacyTermsComponent},
  {path: 'brunch', component: BrunchComponent},
  {path: 'xmas', component: MenuXmasComponent},
  {path: 'nyear', component: NewYearMenuComponent},
  {path: 'updateMenu', component: UpdateMenuComponent, canActivate: [AuthGuard]},
  {path: 'admin', component: AdminManagementComponent},
  {path: 'admin2', component: AdminManagementComponent},
  {path: 'updateDrink', component: UpdateDrinkComponent, canActivate: [AuthGuard]}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: true})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
