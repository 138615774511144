<div class="contenedor-padre">
    <mat-toolbar color="primary">
      <button mat-button [matMenuTriggerFor]="menu">
        <mat-icon>more_horiz</mat-icon>
      </button>
      <mat-menu #menu="matMenu" class="menu">
        <div>
          <button mat-button class="boton" routerLink='/'>
            Inicio
          </button>
        </div>
        <div>
          <button mat-button class="boton" routerLink='booking'>
            Reservas
          </button>
        </div>
        <div>
          <button mat-button class="boton" routerLink='menu'>
            Carta
          </button>
        </div>
        <div>
          <button mat-button class="boton" routerLink='drink'>
            Cócteles
          </button>
        </div>
        <div>
          <button mat-button class="boton" routerLink='xmas'>
            Carta de Navidad
          </button>
        </div>
        <!-- <div>
          <button mat-button class="boton" routerLink='nyear'>
            Cena de Fin de Año
          </button>
        </div>
         <div>
        <button mat-button class="boton" routerLink='brunch'>
            Brunch
          </button>
        </div> -->
        <div>
          <button mat-button class="boton" routerLink='gallery'>
            Galería
          </button>
        </div>
        <div>
          <button mat-button class="boton" routerLink='aboutUs'>
            Sobre Nosotros
          </button>
        </div>
        </mat-menu>
    <span class="example-spacer"></span>
    <button mat-button class="example-icon favorite-icon">
        <a href="https://www.instagram.com/elromerodegaleras/" target="_blank">
            <img class="resize" src="https://api.iconify.design/mdi-instagram.svg"/>
        </a>
    </button>
    <button mat-button class="example-icon">
        <div class="fb-share-button">
            <a target="_blank" href="https://www.facebook.com/ElRomeroSantiago">
                <img class="resize" src="https://api.iconify.design/il-facebook.svg"/>
            </a>
        </div>
   </button>
   <button mat-button class="example-icon">
    <div class="fb-share-button">
        <a target="_blank" href="https://www.tripadvisor.es/Restaurant_Review-g187508-d7278397-Reviews-El_Romero-Santiago_de_Compostela_Province_of_A_Coruna_Galicia.html">
            <img class="resize2" src="https://api.iconify.design/fa-tripadvisor.svg"/>
        </a>
    </div>
  </button>
  <button mat-button class="example-icon" routerLink='admin'>
    <div class="fb-share-button">
        <img class="resize" src="assets/img/account.png"/>
    </div>
</button>
  <button mat-button class="example-icon" routerLink='admin2'>
    <div class="fb-share-button">
        <img class="resize" src="assets/img/account.png"/>
    </div>
</button>
</mat-toolbar>
  <router-outlet></router-outlet>
</div>
<mat-toolbar class="toolbarNav" color="primary">
  <span class="example-spacer"></span>
    <a routerLink="/privacy">Política de Privacidad y Aviso Legal</a>
    <a href="mailto: santiagoromero89@gmail.com" style="padding-left: 1%;">Powered by</a>
</mat-toolbar>
