<div class="textos">
    
    <h1 class="textos">Menú de Fin de Año</h1>

    <h2 class="textos">Empezamos</h2>

    <p>Cóctel de bienvenida.</p>
    <p>Pan de A Moa, mantequilla tradicional y caviar.</p>
    <p>Brandada de bacalao con su torrezno y cítricos.</p>

    <h2 class="textos">Entrante</h2>

    <p>Alcahofa frita, yema de huevo y jugo de ave.</p>
    <p>Empanadilla de cocido con su jugo.</p>

    <h2 class="textos">Primer plato</h2>
    <p>Sopa de Navidad de Lourdes.</p>
    <p>Pescado del día.</p>

    <h2 class="textos">Terminamos</h2>
    <p>Roast beef de ternera IGP con puré de patata Robuchon y zanahoria asada con miel y tomillo.</p>
    
    <h2 class="textos">Postre</h2>
    <p>Tarta Paulova.</p>

    <h2 class="textos">Recibimos el año</h2>

    <p>Uvas de la suerte, dulces navideños y champán GHMum</p>

    <p>Precio: 95.00€/persona - no incluye bodega</p>

</div>