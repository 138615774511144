import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { PopUpComponent } from '../pop-up/pop-up.component';
import { AngularFireAuth } from '@angular/fire/auth';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-admin-management',
  templateUrl: './admin-management.component.html',
  styleUrls: ['./admin-management.component.css']
})
export class AdminManagementComponent implements OnInit {

  env = environment;
  fromMenu = true;
  constructor(public dialog: MatDialog, private router: Router, private afAuth: AngularFireAuth) { }

  ngOnInit(): void {
    console.log(this.router.url);
    if(this.router.url == '/admin2') {
      this.fromMenu = false;
    }
    if(this.env.isLogged === true) {
      if(this.fromMenu) {
        this.router.navigate(['updateMenu']);
      } else {
        this.router.navigate(['updateDrink']);
      }
    }
  }

  onSubmit(form) {
    this.afAuth.signInWithEmailAndPassword(this.env.mail, form.pwd).then(() => {
      const dialogRef = this.dialog.open(PopUpComponent, {
        data: {from:  'adminManagementGood'}
      });
      dialogRef.afterClosed().subscribe(result => {
        console.log('Dialog closed!');
      });
      this.env.isLogged = true;
      if(this.fromMenu) {
        this.router.navigate(['updateMenu']);
      } else {
        this.router.navigate(['updateDrink']);
      }
     }).catch(response => {
       const dialogRef = this.dialog.open(PopUpComponent, {
         data: {from:  'adminManagementWrong'}
       });
       dialogRef.afterClosed().subscribe(result => {
         console.log('Dialog closed!');
       });
       this.router.navigate(['']);
     });
   }

}
