import { Component, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { GalleryImage } from 'src/app/model/gallery-image';
import { Subscription } from 'rxjs';
import { GalleryService } from 'src/app/services/gallery.service';
import { Constants } from 'src/app/utils/constants';

@Component({
  selector: 'app-gallery-modal',
  templateUrl: './gallery-modal.component.html',
  styleUrls: ['./gallery-modal.component.css']
})
export class GalleryModalComponent implements OnInit, OnDestroy {

  public image: GalleryImage;
  private subscription: Subscription;
  private constants = new Constants();

  constructor(
    private ref: ChangeDetectorRef,
    private readonly galleryService: GalleryService
  ) {
  }

  ngOnInit(): void {
    this.getImageSelected();
  }

  getImageSelected(): void {
    this.subscription = this.galleryService
      .getImageSelected()
      .subscribe((image: GalleryImage) => {
        this.image = image;
        this.ref.detectChanges();
      });
  }

  changeImg(move: number): void {
    let position = this.image.position + move;
    if (position > this.constants.maxImages) {
      position = 1;
    }
    this.galleryService.selectImage(position);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
